.countyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto 35px;
  max-width: 798px;
}
.contentContainer {
  width: 100%;
  flex: 1;
  margin-top: 0;

  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
.countryContainer:focus {
  color: #fff;
}
.contentContainerMobile .countyContainer {
  margin: 0px 30px 35px;
}
.contentContainerMobile .header {
  margin: 23px auto 33px;
}
.header {
  margin: 40px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;

  color: #454452;
}
.countryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  height: 297px;
  width: 250px;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  overflow: hidden; 
}
.countryContainer .background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s ease;
}
.countryContainer:hover .background {
  transform: scale(1.2);
}
.contentContainerMobile:hover .background {
  transform: scale(1);
}
.contentContainerMobile .countryContainer {
  width: 300px;
  height: 190px;
}
.country {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 60px;

  font-size: 24px;
  font-weight: 500;
  line-height: 0%;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.contentContainerMobile .country {
  bottom: 40px;
}