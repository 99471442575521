
.input{
  width: 100%;
}
.input label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.error {
  position: absolute;
  top: 65px;
  margin-top: 0 !important;
}
.errorTextArea {
  position: absolute;
  bottom: -20px;
  margin-top: 0 !important;
}
.input label[data-shrink="true"] {
  font-size: 14px;
  transform: translate(0, 1.5px) scale(1);
}
.input div[type="text"] {
  display: flex;
  padding: 0;
}
.input div[type="text"] > div {
  padding: 0;
  font-weight: 500;
}

.input>div:before,
.input>div:hover:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.input>div:after {
  border-bottom: 1px solid #31e495;
}
.input input {
  padding: 14px 0 10px;
  color: #454452;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.04em;
}
.input textarea {
  min-height: 30px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.04em;
}
.visibilityIcon {
  color: #31e495;
}
.password {
  position: relative;
}
.toggleVisibility {
  position: absolute;
  right: 0;
  top: 29px;
  
  padding: 0;
  
  width: 25px;
  height: 20px;
}
.toggleVisibility>svg {
  width: 25px;
  height: 20px;
}
.toggleVisibility:hover {
  cursor: pointer;
}
.password button {
  background-color: transparent;
}
.password input {
  padding-right: 25px;
}