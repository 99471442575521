.resultsContainer {
  margin: 45px auto 0;
  width: 100%;
  flex: 1;
  max-width: 740px !important;
  min-height: 450px;
  padding: 0 0 110px !important;
}
.resultsContainerMobile {
  padding: 0 30px 95px !important;
  margin: 35px auto 0;
}
.backGround {
  position: absolute;
  z-index: -2;
  top: 365px;
  right: calc((100% - 100vw)/2);
  left: calc((100% - 100vw)/2);
  bottom: 0;
  background: #EAFFF6;
}
.resultsContainerMobile .backGround {
  display: none;
}
.backGroundPict {
  position: absolute;
  z-index: -1;
  top: 142px;
  right: calc((100% - 100vw)/2);
  left: calc((100% - 100vw)/2);
  max-width: 1365px;
  margin: 0 auto;
  bottom: 0;
  display: inline-flex;
  justify-content: space-between;
}
.backGroundPict svg {
  z-index: 1;
  width: 375px;
  height: 224px;
}
.backGroundPict svg:last-child {
  height: 240px;
}
.tip {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #454452;

  display: flex;
  justify-content: center;
}
.tip .separator {
  margin: 0 12px;
  height: 21px;
  width: 2px;
  background: #454452;
}
.resultsContainerMobile .tip {
  bottom: 228px;
  text-align: left;
  margin: 37px auto 43px;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
}
.resultsContainerMobile span:last-child {
  max-width: 230px;
}
.resultsContainerMobile .separator {
  height: 53px;
}
.resultsContainerMobile .backGroundPict {
  top: auto;
  bottom: 50px;
  z-index: 1;

  display: flex;
  justify-content: center;
}
.resultsContainerMobile .backGroundPict svg:first-child {
  width: 278.27px;
  height: 175.62px;
}
.resultsContainerMobile .backGroundPict svg:last-child {
  display: none;
}
.resultsContainer h1 {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 32px;
  line-height: 49px;
  color: #454452;
  text-align: center;
}
.resultsContainerMobile h1 {
  margin: 0 0 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.resultsContainer h5 {
  margin: 0 auto 70px;
  color: #5C5B66;
  font-weight: normal;
  font-size: 26px;
  line-height: 40px;
  max-width: 470px;
  text-align: center;
}
.resultsContainerMobile h5 {
  margin: 0 0 38px;
  font-size: 18px;
  line-height: 27px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resultsContainerMobile .container {
  background: #EAFFF6;
  margin: 0 -30px;
  padding: 30px 30px 275px;
}
.visaBlock {
  text-decoration: none;
  text-transform: initial;
}
.one {
  margin: 20px 0 78px !important;
}
.resultsContainerMobile .one {
  margin: 0 !important;
}
.visaCard:hover {
  background: #31E495;
}
.resultsContainerMobile .visaCard:hover {
  background: #fff;
}
.visaCard:hover .visaDescription {
  display: flex;
}
.visaCard:hover .visaTitle {
  display: none;
}

.resultsContainerMobile .visaCard:hover .visaDescription {
  display: none;
}
.resultsContainerMobile .visaCard:hover .visaTitle {
  display: flex;
}

.resultsContainerMobile .isOpen .visaDescription {
  display: flex !important;
  height: 142px;
}
.resultsContainerMobile .isOpen .visaTitle {
  display: none !important;
}

.visaCard:last-child {
  margin-bottom: 0;
}
.resultsContainerMobile .visaCard {
  margin-bottom: 30px;
  padding-bottom: 42px;
}
.resultsContainerMobile .visaCard:last-child {
  margin-bottom: 0;
}
.visaCard {
  width: 100%;
  max-width: 480px;
  height: 100px;

  background: #fff;

  border: 2px solid #31E495;
  border-radius: 2px;
  
  text-decoration: none;
  text-transform: initial;
  margin-bottom: 14px;
}
.resultsContainerMobile .visaCard {
  position: relative;
  height: 142px;
}
.resultsContainerMobile .isOpen.visaCard {
  background: #31e495;
}

.visaTitle {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #31E495;
}
.visaTitle svg {
  width: 30px;
  margin-bottom: 8px;
}
.visaDescription {
  padding: 30px 38px;
  display: none;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #fff;
}
.resultsContainerMobile .visaDescription {
  padding: 41px 15px 6px;
}
.push {
  display: none;
}
.resultsContainerMobile .push {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  height: 34px;
  background: #31E495;
}
.resultsContainerMobile .isOpen .push {
  top: 0;
}
.resultsContainerMobile .isOpen .push svg {
  transform: rotate(180deg);
}