.userContainer {
  display: flex;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 910px;
}
.SPTheme.userContainer .titleLink {
  color: #0459FF;
  display: block;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.04em;
  margin-bottom: 23px;
}
.pageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  margin-left: 0 !important;
}
.userContainerMobile {
  flex-direction: column;
  padding: 0 15px;
}
.navigation {
  width: 300px;
  border-right: 1px solid #e4e4e4;
}
.tooltip {
  min-width: 85px;
  margin-left: 15px;
}
.userContainerMobile .navigation {
  width: 100%;
  margin: 0;
  border-right: 0;
  border-bottom: 1px solid #e4e4e4;
}
.navigation a:focus {
  color: #31e495;
}
.SPTheme .navigation a:focus {
  color: #0459FF;
}
.content {
  display: flex;
  flex-direction: column;

  flex: 1;
  margin-left: 80px;
  max-width: 540px;
}
.userContainerMobile .content {
  margin: 37px 0 40px;
}
.link {
  font-size: 18px;
  line-height: 23.4px;
  color: #b1afba;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.link > svg {
  margin-right: 12px;
}
.link:hover {
  color: #31e495;
}
.link:hover>svg path {
  fill: #31e495;
}
.selected {
  color: #31e495;
}
.selected>svg path {
  fill: #31e495;
}
.SPTheme .link:hover {
  color: #0459FF;
}
.SPTheme .link:hover>svg path {
  fill: #0459FF;
}
.SPTheme .selected {
  color: #0459FF;
}
.SPTheme .selected>svg path {
  fill: #0459FF;
}
.contentTitle {
  margin-bottom: 65px;
}
.providerContainer.contentTitle {
  margin-bottom: 0;
}
.providerContainer h1 {
  margin: 65px 0 30px !important;
}
h1.titleInfo {
  margin: 0 0 22px !important;
}
.providerContainer h1:first-child {
  margin: 0 0 30px !important;
}
.providerContainer .info {
  margin-bottom: 35px;
}
.providerContainer .info:last-child {
  margin-bottom: 0;
}
.providerPhoto {
  margin: 0 30px 35px;
  height: 70px;
  width: 70px;
  border: 2px solid #0459FF;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;
}
.contentTitleMobile {
  margin-bottom: 39px;
}
.formContainer h1,
.contentTitle h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.formContainer h1.title {
  margin-top: 65px;
}
.formContainer h1.phone {
  margin: 30px 0 30px -30px;
}
.subTitle {
  margin: 0 30px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #858297;
}
.info {
  margin: 0 30px;
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;
}
.talk {
  margin: 0;
  font-weight: normal;
  line-height: 26px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #454452;
  display: flex;
  justify-content: space-between;
}
.chatContainerMobile .talk>div{
  flex: 1;
}
.talk a {
  color: #31e495;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}
.chatContainerMobile .talk>div>a {
  display: block;
}
.SPTheme .talk a {
  color: #0459FF;
}
.SPTheme .talk {
  color: #454452;
  text-decoration: none;
}
.settingsPageContainer {
  flex: 1;
  display: flex;
  width: 100%;
}
.blockContainer {
  border: 1px solid #b7b3ce;
  margin-left: 30px;
  padding: 28px 40px 26px;
  width: 440px;
}
.blockContainerMobile {
  width: 240px;
  padding: 28px 20px 26px;
} 
.forgot {
  background: #EAFFF6;
  border: 1px solid #31e495;
  padding: 15px 27px;
}
.SPTheme.forgot {
  background: #EDF3FF;
  border: 1px solid #0459FF;
}
.blockContainer a {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;

  display: flex;
  align-items: center;
}
.SPTheme.blockContainer a {
  color: #0459FF;
}
.SPTheme.blockContainer a svg path {
  fill: #0459FF;
}
.blockContainer h3 {
  margin: 0 0 4px;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #31e495;
  height: auto;
  text-align: left;
}
.SPTheme.blockContainer h3 {
  color: #0459FF;
}
.blockContainer p {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #5C5B66;
}

.blockContainer a > svg {
  margin-left: 10px;
}
.form > div {
  position: relative;
  margin: 0 0 35px;
}
.userContainer .form {
  margin-left: 30px;
} 
.userContainerMobile .form {
  margin-right: 30px;
}
.Button {
  max-width: 136px;
  margin-left: auto;
  margin-top: 36px;
}
.ButtonCnl {
  max-width: 136px;
  margin-top: 36px;
}
.userContainerMobile .Button {
  margin: 36px auto 0;
}
.error {
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
}
.error {
  color: #f44336;
}
.confirmationContainer {
  margin: 36px 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.success {
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: .04em;
  color: #31e495;
}

.containerX {
  display: flex;
  align-items: stretch;
  margin: 40px auto;
  width: 100%;
  flex: 1;
  max-width: 960px !important;
}
.noMargin {
  margin: 0 auto;
  padding: 0 !important;
}

.chatListContainer {
  margin: 0 40px 0 0;
  padding: 0 20px;
  width: 355px;
  border: 1px solid #E5E4EE;
  border-radius: 2px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.chatListContainer::-webkit-scrollbar {
  width: 8px;
}
.chatListContainer::-webkit-scrollbar-track {
  background: transparent;
}
.chatListContainer::-webkit-scrollbar>* {
  background:transparent;
}
.chatListContainer::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.chatListContainer::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.chatListContainer::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}
.chatListContainerMobile {
  margin: 0 auto;
  max-height: inherit;
}
.ChatItem {
  display: flex;
  padding: 26px 0 20px;
  border-bottom: 1px solid #E5E4EE;
  text-decoration: none;
  position: relative;
}
.selectedChat {
  border-left: 3px solid #31E495;
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.selectedChat .date {
  right: 20px !important;
}
.ChatItem:hover {
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px;
}
.selectedChat.ChatItem:hover {
  background: #EAFFF6;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.SPTheme .ChatItem:hover {
  background-color: #EDF3FF;
}
.ChatItem:hover .date {
  right: 20px;
}
.SPTheme .selectedChat {
  border-left: 3px solid #0459FF;;
  background: #EDF3FF;;
  margin: 0 -20px;
  padding: 26px 20px 20px 17px;
}
.SPTheme .selectedChat.ChatItem:hover {
  padding: 26px 20px 20px 17px;
}
.SPTheme .selectedChat .date {
  right: 20px !important;
}
.ChatItem:last-child {
  border-bottom: 0;
}
.ChatItem .circle {
  height: 42px;
  width: 42px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.SPTheme .ChatItem .circle {
  height: 42px;
  width: 42px;
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  margin-right: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #B3CDFF;
}
.isRead .circle {
  border: 1px solid #31e495;
}
.SPTheme .isRead .circle {
  border: 1px solid #0459FF;
  box-shadow: 0px 4px 9px rgba(193, 186, 186, 0.25)
}
.ChatItem h5 {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
}
.isRead h5 {
  color: #454452;
}
.ChatItem p {
  margin: 3px 0 0;
  white-space: pre-line;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
  text-transform: none;
}
.isRead p {
  color: #858297;
  position: relative;
  font-weight: 500;
}
.isRead p::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "\A";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #31e495;
}
.SPTheme .isRead p::after {
  background: #0459FF;
}
.ChatItem .date {
  position: absolute;
  right: 0;
  top: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #B1AFBA;
}
.ChatItem .container {
  flex: 1;
}

.textBoxContainer {
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding-top: 20px;
  background: #fff;
}
.textBoxContainer .textInput {
  max-height: 126px;

  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #454452;

  border: 1px solid #E5E4EE;
  border-radius: 6px;
  padding: 14px;
  flex: 1;
  
  word-break: break-all;
  resize: none;
  overflow: auto;
}
.textBoxContainer .textInput:hover {
  border: 1px solid #B7B3CE;
}
.textBoxContainer .textInput:focus {
  border: 1px solid #454452;
}
.textBoxContainer .textInput::placeholder {
  color: #B1AFBA;
}
.textInput::-webkit-scrollbar {
  width: 4px;
}
.textInput::-webkit-scrollbar-track {
  background: transparent;
}
.textInput::-webkit-scrollbar>* {
  background:transparent;
}
.textInput::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.textInput::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.textInput::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.textSubmitButton {
  margin-left: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #31E495;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textSubmitButton:disabled {
  background: #DFE7F2;
}
.textSubmitButton:disabled svg path {
  fill: #B4C6DC;
}
.textSubmitButton > svg {
  margin-left: -3px;
}
.SPTheme .textSubmitButton {
  background: #0459FF;
}
.SPTheme .textSubmitButton:disabled {
  background: #DFE7F2;
}



.chatContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
  flex: 1;
  position: relative;
}
.chatContainerMobile {
  margin: -40px 0;
  padding-top: 20px;
  max-height: none;
  position: fixed;
  width: calc(100vw - 30px);
  top: 110px;
  bottom: 40px;
}
.chatContainerMobile .textBoxContainer {
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
}
.chatContainer > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}
.chatContainerMobile  > div {
  margin-bottom: 90px;
}
.chatContainer .talk {
  padding: 0 0 20px;
  border-bottom: 1px solid #E5E4EE;
  margin: 0 0 20px;

  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #454452;
  flex-shrink: 0;
}
.results {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  background: #F3F2F9;
  border: 1px solid #B7B3CE;
  box-shadow: 0px 9px 30px rgba(103, 105, 118, 0.04);
  border-radius: 2px;
  width: 100%;
  height: auto !important;
  padding: 20px 13px 20px 20px;
}
.results h5 {
  margin: 0 0 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: #454452;
}
.results h4 {
  margin: 0 0 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: #454452;
}
.results .resultsVisas,
.results p {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;

  color: #5C5B66;
}
.results .resultsVisas {
  font-weight: bold;
}
.results .resultsVisas.provider {
  font-weight: normal;
}
.SPTheme .results span:after,
.SPTheme .results span:before,
.SPTheme .results div:after{
  background-color: #0459FF;
}
.messagesContainer {
  overflow-y: auto;
  height: 100%;
}
.messagesContainer::-webkit-scrollbar {
  width: 8px;
}
.messagesContainer::-webkit-scrollbar-track {
  background: transparent;
}
.messagesContainer::-webkit-scrollbar>* {
  background:transparent;
}
.messagesContainer::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.messagesContainer::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.messagesContainer::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.message {
  display: flex;
  margin: 0 28px 39px 0;
  text-decoration: none;
  position: relative;
}
.chatContainerMobile .message {
  margin: 0px 10px 40px 15px;
}
.message .circle {
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e5e5e5;
  border-radius: 50%;
}
.message .userLogo {
  height: 30px;
  width: 30px;
  background-color: #fff;
}
.SPTheme .message .userLogo {
  border: 1px solid #0459FF;
}
.message h5 {
  margin: 0;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: #B1AFBA;
}
.message p {
  word-break: break-word;
  white-space: pre-line;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.04em;

  color: #454452;
}
.message .date {
  position: absolute;
  bottom: -23px;
  right: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #B1AFBA;
}
.message .container {
  flex: 1;
  margin-left: 10px;
  padding: 20px;
  border: 1px solid #E5E4EE;
  border-radius: 10px;
  border-top-left-radius: 2px;
}
.message .container.from {
  background-color: #F3F2F9;
  border: 1px solid transparent;
}

.ChangePasswordContainer h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}
.ChangePasswordContainer .info {
  margin-bottom: 35px;
}

.noMessages {
  margin: auto;
  width: 100%;
  max-width: 390px;
  height: 248px;
  padding: 46px 64px 51px;
  border: 1px solid #E5E4EE;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
}
.noMessages h1 {
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #5C5B66;
}
.noMessages>p {
  margin: 8px 0 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #5C5B66;
}
.noMessages .ButtonLink {
  margin: 0 auto;
  max-width: 176px;
}





/*Styles for Registration Form*/
.registrationContainer {
  height: 100%;
  display: flex;
  max-width: 438px;
}
.userContainerMobile .registrationContainerMobile>form {
  margin-right: 0;
  width: 100%;
}
.registrationContainer .separator {
  margin-top: 40px !important;
  height: 1px;
  background-color: #B7B3CE;
}
.registrationContainer .indent1 {
  margin-bottom: 35px !important;
}
.registrationContainer .indent2 {
  margin-bottom: 45px !important;
}
.registrationContainer .description,
.registrationContainer .indent3 {
  margin-bottom: 15px !important;
}
.registrationContainerMobile .description label {
  top: -19px;
}
.registrationContainer .indent4 {
  margin: 0 0 15px !important;
}
.registrationContainer .form>.DropdownContainer {
  margin-bottom: 30px !important;
}
.indent1>div:after,
.indent2>div:after,
.description>div:after,
.indent3>div:after,
.indent4>div:after,
.indent1>div>div>div:after,
.indent2>div>div>div:after,
.indent3>div>div>div:after,
.indent4>div>div>div:after,
.textArea>div:after,
/* .DropdownContainer>div>div:after, */
.input>div:after {
  border-bottom: 1px solid #0459FF;
}
.subForm form>div>div:after,
.subForm form>div>div>div:after {
  border-bottom: 1px solid #0459FF;
}
.registrationContainer .sectionTitle {
  margin-top: 55px;
}
.registrationContainer .counter {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #858297;
  text-align: right;
  margin-bottom: 3px !important;
}
.registrationContainer .form > div {
  margin: 0;
}
.registrationContainer h1 {
  position: relative;
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #454452;
}
.registrationContainer h1>div {
  margin-left: 8px;
}
.registrationContainer h1>button {
  margin-left: 14px;
}
.registrationContainer h2 {
  margin: 0 0 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #858297;
}
.registrationContainer h2>div {
  margin-left: 8px;
}
.registrationContainer h2>button {
  margin-left: 14px;
}
.userContainer .registrationContainer form {
  margin-left: 0;
}
.registrationContainer a,
.registrationContainer form>p {
  margin: 0 0 20px;
  font-size: 12px;
  letter-spacing: .8px;
  color: #454452;
}
.registrationContainer .ButtonSecondary {
  color: #0459FF;
  height: initial;
  display: inline;
  text-decoration: underline;
}
.registrationContainer .ButtonSecondary p {
  margin: 0;
}
.registrationContainer a {
  color: #0459FF;
}
.registrationContainer .error {
  color: #FF3C3C;
  font-size: 0.75rem;
  text-align: left;
  min-height: 1em;
  line-height: 1em;
  letter-spacing: initial;
}
.registrationContainer .tip {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #b1afba;
  letter-spacing: initial;
}
.registrationContainer .loading {
  font-size: 12px;
  letter-spacing: .8px;
  color: #0459FF; 
}

/*Styles for Gender field*/
.radioButtonContainer {
  margin-bottom: 43px !important;
}
.radioButtonContainer .error {
  position: absolute;
  left: 0;
  bottom: -16px;
  margin-bottom: 0;
  color: #FF3C3C;
  font-size: 0.75rem;
  text-align: left;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
  letter-spacing: initial;
}
.radioButtonContainer .radio>span:first-child {
  padding: 8px;
}
.radioButtonContainer .radio>span:last-child {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .04em;
  text-transform: capitalize;
  color: #b7b3ce;
}
.radioButtonContainer .radioGender {
  margin-left: 0;
  margin-right: 40px;
}
.registrationContainerMobile .radioButtonContainer .radioGender {
  margin-right: 20px;
}
.radioButtonContainer .radioGender>span {
  padding-left: 0;
  padding-right: 0;
}
.radioButtonContainer .radioGender:hover svg circle {
  stroke: #0459FF;
}
.radioButtonContainer .radioGender>span:last-child {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .04em;
  text-transform: capitalize;
  color: #b7b3ce;
}
.radioButtonContainer .radioGender.selected>span:last-child {
  color: #454452;
  font-weight: 500;
}
/*Styles for Gender field*/





/*Styles for Profile Picture*/
.profilePicture {
  margin-bottom: 50px !important;
}
.avatar {
  position: relative;
  width: max-content;
}
.avatar .add {
  position: absolute;
  top: 6px;
  right: 6px;
}
.avatar .add:hover {
  cursor: pointer;
}
.avatar>div>div {
  min-width: 200px;
}
.avatar>div>div:hover {
  border-color: #0459FF !important;
}
.avatar>div>div label {
  height: 130px;
  display: block;
  position: relative;
}
.avatar>div>div label:hover {
  cursor: pointer;
}
.avatar>div>div label:after {
  content: url(common/assets/plusBig.svg);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.profilePicture .imagePreview {
  position: relative;
  min-width: 200px;
  min-height: 30px;
  width: max-content;
}
.profilePicture .imagePreview img {
  height: 150px;
  width: auto;
}
.profilePicture .imagePreview .remove {
  position: absolute;
  top: 6px;
  right: 6px;
}
.profilePicture .imagePreview .remove:hover {
  cursor: pointer;
}
/*Styles for Profile Picture*/




/*Styles for Start Popup*/
.registrationContainer .registrationPopup {
  width: 682px;
  min-height: 553px;
  padding: 51px 60px;
}
.registrationContainerMobile .registrationPopup {
  width: 300px;
  max-height: 600px;
  padding: 11px 10px;
}
.registrationPopup div>svg {
  width: 140px;
  height: 136px;
  margin-bottom: 33px;
}
.registrationContainerMobile .registrationPopup div>svg {
  width: 70px;
  height: 68px;
  margin-bottom: 13px;
}
.registrationPopup div>h1 {
  font-weight: 500;
  font-size: 26px;  
  line-height: 35px;
  color: #454452;
  margin-bottom: 5px;
}
.registrationPopup div>p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #454452;
  margin: 0 0 41px;
}
.registrationPopup div>ul {
  list-style: none;
  margin: 0 0 45px;
  padding: 0;
  text-align: left;
}
.registrationPopup div>ul li {
  display: inline-flex;
}
.registrationPopup div>ul li svg {
  width: 30px;
  margin-right: 14px;
}
.registrationPopup div>ul li span {
  flex: 1;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454452;
  padding-bottom: 24px;
}
.registrationPopup div>ul li:last-child span {
  padding-bottom: 0;
}
.registrationPopup div>.Button {
  max-width: 205px;
}
.registrationPopup>button {
  display: none;
}
/*Styles for Start Popup*/



/*Styles for Forms */
.subForm {
  margin-top: 65px !important;
}
.subForm>form {
  margin-top: 14px;
  background: #F7F6FB;
  padding: 30px;
}
.subForm>form .container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.subForm form .container>div:first-child {
  width: 120px;
  margin-right: 20px;
}
.subForm form .container>div:last-child {
  flex: 1;
}
.subForm form>div {
  margin-bottom: 35px;
}
.subForm form>div:last-child {
  margin-bottom: 0;
}
.subForm .buttonContainer {
  justify-content: flex-end;
}
.subForm .Button,
.subForm .ButtonCnl {
  max-width: 106px;
  margin: 0 0 0 10px;
}
.subForm h1 {
  margin: 0;
}
.subForm .tip {
  margin: 10px 0 0;
}
.buttonContainer {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.documentList {
  margin-top: 14px;
}
.documentItem {
  width: 100%;
  padding: 20px 65px 20px 18px;
  border: 1px solid #B7B3CE;
  position: relative;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.documentItem:last-child {
  margin-bottom: 0;
}
.documentItem .remove {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.documentItem .remove:hover {
  cursor: pointer;
}
.documentItem>div:nth-child(2) {
  max-width: 288px;
}
.registrationContainerMobile .documentItem>div:nth-child(2) {
  max-width: 200px;
}
.documentItem .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #454452;
  margin-bottom: 7px;
}
.documentItem .description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #454452;
  margin-bottom: 7px;
}
.documentItem .file {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #858297;
  display: flex;
  align-items: center;
  word-break: break-word;
}
.documentItem .file svg {
  margin-right: 7px;
}
.documentItem img {
  width: auto;
  height: 54px;
  border: 1px solid #E5E4EE;
}
/*Styles for Forms */

.DropdownContainer {
  width: 100%;
}
.DropdownContainer>div:nth-child(3)>div>div:last-child {
  border-bottom: 0 !important;
}
/*custom scroll*/
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar {
  width: 8px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-track {
  background: transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar>* {
  background:transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.registrationContainer .textArea {
  margin: 35px 0 10px !important;
}
.registrationContainer .textArea>div>div {
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}
/*custom scroll*/
.registrationContainer .textArea>div>div::-webkit-scrollbar {
  width: 8px;
}
.registrationContainer .textArea>div>div::-webkit-scrollbar-track {
  background: transparent;
}
.registrationContainer .textArea>div>div::-webkit-scrollbar>* {
  background:transparent;
}
.registrationContainer .textArea>div>div::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.registrationContainer .textArea>div>div::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.registrationContainer .textArea>div>div::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

.contactContainer form {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-top: 15px;
}
.contactContainer form .remove {
  margin-left: 20px;
}
.contactPreview .label>span {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #858297;
  margin-right: 6px;
}
.contactPreview .label button {
  margin-left: 14px;
}
.contactPreview .contact {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #454452;
  margin-top: 10px;
}