.container {
  flex: 1;
  display: flex;
  background-color: #F7F6FB;
}
.questionContainer {
  flex: 1;
  display: flex;
  margin: 140px 0 70px;
}
.background {
  position: absolute;
  top: -70px;
  right: calc((100% - 100vw)/2);
  left: calc((100% - 100vw)/2);
  bottom: -70px;
  background-image: url(common/assets/usa_background_2.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.questionContainerMobile {
  flex-direction: column;
  margin: 70px 0 0;
  padding: 30px 30px 40px !important;
}
.questionContainerMobile .background {
  top: 0;
  bottom: 0;
  background-position-x: -1180px;
}
.questionContainer>div:first-child {
  margin-top: 21px;
  margin-right: 95px;
}
.questionContainerMobile>div:first-child {
  margin: 0;
}
.form {
  position: relative;
  width: 400px;
  height: 100%;
  padding: 30px;

  background: #FFFFFF;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
}
.form>div {
  margin-bottom: 35px;
}
.questionContainerMobile .form {
  width: auto;
}
.title {
  font-family: Lato;
  font-weight: bold;
  font-size: 38px;
  line-height: 49px;
  color: #454452;
  margin: 0 0 15px;
}
.questionContainerMobile .title {
  font-size: 24px;
  line-height: 34px;
  max-width: 262px;
}
.description {
  max-width: 635px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #5C5B66;
  margin: 0 0 15px;
}
.questionContainerMobile .description {
  width: auto;
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 18px;
}
.payContainer {
  display: flex;
  align-items: center;
}
.questionContainer .payContainer .wrapper {
  margin-left: 20px;
  max-width: 535px;
}
.questionContainerMobile .payContainer {
  margin-bottom: 20px;
}
.questionContainerMobile .payContainer .wrapper {
  margin-left: 12px;
  max-width: 260px;
}
.questionContainerMobile .payContainer .wrapper h5 {
  display: inline;
}
.questionContainerMobile .payContainer>svg {
  width: 27px;
  height: 38px;
}
.tip {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #5C5B66;
  margin: 0;
}
.questionContainerMobile .tip {
  font-size: 14px;
  line-height: 24px;
}
.Button {
  max-width: 136px;
  margin: 60px auto 0;
}
.form>p {
  position: absolute;
  bottom: 95px;
  left: 50px;
  right: 50px;

  margin: 0;
  text-align: center;

  font-weight: 500;
  font-size: 14px;

  line-height: 20px;
}
.success {
  color: #31E495;
}
.error {
  color: #FF3C3C;
}