.providersContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 50px auto 30px;
  max-width: 956px;
}
.contentContainer {
  width: 100%;
  flex: 1;
  margin-top: 0;

  display: flex;
  flex-direction: column;
}
.contentContainerMobile .countyContainer {
  margin: 0px 30px 35px;
}
.header {
  margin: 0 0 23px;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;

  color: #454452;
}
.header span {
  color: #31E495;
}
.contentContainerMobile .header {
  margin: 0 20px 23px;
  text-align: left;
  font-size: 18px;
  line-height: 27px;
}
.cardContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
  height: 157px;
  border: 1px solid #31E495;
  border-radius: 6px;
  margin-bottom: 20px;
}
.cardContainer:last-child {
  margin-bottom: 0;
}
.cardContainerMobile {
  margin: 0 auto 30px;
  flex-direction: column;
  height: auto;
  width: 300px;
}
.cardContainer .providerHeader {
  position: relative;
  margin-right: 73px;
  background-color: #31E495;
  flex: 0 0 113px;
  height: 100%;
}
.cardContainerMobile .providerHeader {
  margin-right: 0;
  background-color: #31E495;
  flex: 0 0 59px;
  width: 100%;
}
.cardContainer .providerHeader svg:first-child {
  position: absolute;
  top: 16px;
  left: 16px;
}
.cardContainerMobile .providerHeader svg:first-child {
  top: 22px;
  left: 18px;
}
.cardContainer .providerHeader svg:nth-child(2) {
  transform: rotate(-90deg);

  position: absolute;
  top: 20px;
  right: -28px;

  height: 117px;
  width: 115px;
}
.cardContainerMobile .providerHeader svg:nth-child(2) {
  transform: rotate(0);

  bottom: 0;
  top: initial;
  left: 105px;

  height: auto;
  width: auto;
}
.cardContainer .providerHeader .providerPhoto {
  position: absolute;
  top: 33px;
  right: -45px;

  width: 91px;
  height: 91px;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: #fff;

  border: 2px solid #FFFFFF;
  border-radius: 50%;

  box-shadow: 0px 4px 9px rgba(193, 186, 186, 0.25)
}
.cardContainerMobile .providerHeader .providerPhoto {
  top: 25px;
  left: 115px;

  width: 70px;
  height: 70px;
}
.cardContainer .priceSection {
  width: 176px;
  margin: 0;
  text-align: center;
}
.cardContainerMobile .priceSection {
  margin: 0;
  width: 176px;
}
.priceSection>p {
  font-size: 16px;
  line-height: 22px;
  color: #454452;
  margin: 0 0 18px;
}
.bold {
  font-weight: bold;
}
.cardContainerMobile .ButtonLink {
  margin: 0 0 21px;
}
.cardContainer .providerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: 56px;
  max-width: 135px;
  height: 100%;
}
.cardContainerMobile .providerInfo {
  height: auto;

  padding: 60px 30px 15px;
  margin: 0;
  max-width: initial;
  width: 100%;
}
.providerInfo .providerName {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #454452;
  margin-bottom: 5px;
  width: 135px;
}
.cardContainerMobile .providerInfo .providerName {
  width: auto;
}
.providerInfo .providerType {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #858297;
}
.cardContainer .props {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  flex: 1;
  height: 100%;

  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #5C5B66;
  text-align: justify;
  max-width: 310px;
  margin-right: 50px;
}
.cardContainerMobile .props {
  padding: 0 30px 30px;
  margin-right: 0;
  max-width: initial;
}
.ButtonLink {
  height: 46px;
  width: 100%;
  padding: 0;
    
  display: flex;
  align-items: center;
  justify-content: center;
    
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
    
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  
  transition: .3s;

  color: #ffffff;
  background-color: #31e495;
}
.ButtonLink:hover {
  cursor: pointer;
  background-color: #2eda8f;
}
.ButtonLink:focus {
  color: #fff;
}
.ButtonLink:active {
  background-color: #35c988;
}

.messageContainer {
  background: #F3F2F9;
  border: 1px solid #B7B3CE;
  box-shadow: 0px 9px 30px rgba(103, 105, 118, 0.04);
  border-radius: 2px;
  position: relative;
  margin: 0 0 18px;
  display: flex;
  align-items: center;
}
.messageContainer .icon {
  width: 25.44px;
  height: 36.52px;
  margin: 0 14px 0 20px;
}
.messageContainer .close {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 13px;
  right: 12px;
  cursor: pointer;
}
.messageContainer p {
  margin: 16px 0 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #454452;
}
.contentContainerMobile .messageContainer p {
  max-width: 190px;
}