.homeContainer {
  width: 100%;
  flex: 1;
  margin-top: 100px;

  display: flex;
  flex-direction: column;
}
.homeContainerMobile {
  padding: 0 30px !important;
}
.homeContainer .title {
  display: flex; 
  justify-content: space-between;
  max-width: 980px;
}
.homeContainerMobile .title {
  display: flex;
  flex-direction: column;
}
.title>h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 51px;
  color: #454452;
  margin: 0;
  max-width: 512px;
  /* letter-spacing: .01px; */
}
.homeContainerMobile .title>h1 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
}
.title>div:last-child {
  max-width: 429px;
}
.title div>p {
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  color: #5C5B66;
  margin: 0 0 30px;
}
.homeContainerMobile .title>p {
  font-size: 16px;
  line-height: 27px;
}
.homeContainerMobile .title div>p {
  margin: 0 0 35px;
  font-size: 16px;
  line-height: 27px;
  max-width: 285px;
}
.title .Button {
  max-width: 240px;
  text-transform: initial;
  font-size: 16px;
  font-weight: 400;
  transition: none;
}
.homeContainerMobile .Button {
  margin: 16px auto;
  width: 220px !important;
}
.start {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 678px;
  padding-top: 100px;
}
.startBackground {
  position: absolute;
  top: 48px;
  right: calc((100% - 100vw)/2);
  left: calc((100% - 100vw)/2);
  bottom: 0;
  background-image: url(common/assets/usa_background.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.homeContainerMobile .startBackground {
  left: -30px;
  right: -30px;
  top: 30px;
  background-image: url(common/assets/usa_background_mobile.png);
  background-position: 35% center;
}
.start>h1,
.start>p {
  max-width: 570px;
  color: #fff;
}
.start h1 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 49px;
  margin: 0 0 20px;
}
.start>p {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0 0 30px;
  max-width: 400px;
}
.start .Button {
  width: fit-content;
  padding: 0 21px;
  width: 220px;
  background-color: #fff;
  text-transform: none;
  color: #5C5B66;
  display: flex;
  flex-direction: row-reverse;
  transition: none;
  font-size: 16px;
}
.start .Button:hover {
  color: #31e495;
}
.start .Button:hover>svg path {
  fill: #31e495;
}
.start .Button:focus {
  outline: none;
  color: #31e495;
}
.start .Button:focus>svg path {
  fill: #31e495;
}
.start .Button>svg {
  margin: 0 0 0 10px !important;
}
.homeContainerMobile .start {
  height: 428px;
  padding-top: 70px;
}
.homeContainerMobile .start h1 {
  font-size: 24px;
  line-height: 31px;
  margin: 0 0 20px;
  max-width: 234px;
  margin-bottom: 24px;
}
.homeContainerMobile .start>p {
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 30px;
  max-width: 188px;
}
.services>h1,
.destinations>h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #454452;
  margin: 50px 0 30px;
}
.services,
.destinations {
  margin-right: -15px;
}
.homeContainerMobile .services,
.homeContainerMobile .destinations {
  margin-right: 0;
}
.homeContainerMobile .services>h1,
.homeContainerMobile .destinations>h1 {
  margin: 60px 0 37px;
}
.homeContainerMobile .destinations>h1 {
  margin: 80px 0 37px;
}
.homeContainerMobile .services,
.homeContainerMobile .destinations {
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
}
.sliderItem {
  padding-right: 20px;
  text-decoration: none;
}
.backgroundOuter {
  max-width: 267px;
  overflow: hidden;
  height: 239px;
  border-radius: 4px;
}
.homeContainerMobile .sliderDestinations .backgroundOuter {
  width: 267px;
}
.sliderItem .background {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s ease;
}
.sliderItem:hover .background {
  transform: scale(1.2);
}
.homeContainerMobile .sliderItem:hover .background {
  transform: scale(1);
}
.sliderItem .country {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: #454452;
  margin: 25px 0 8px;
}
.sliderItem>p:last-child {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
  color: #454452;
  margin: 0;
}
.nextArrow {
  position: absolute;
  z-index: 1;
  top: 98px;
  right: -6px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #31E495;
  color: #31e495;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.prevArrow {
  position: absolute;
  z-index: 1;
  top: 98px;
  left: -16px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #31E495;
  color: #31e495;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: rotate(-180deg);
}
.prevArrow:hover,
.nextArrow:hover {
  cursor: pointer;
}
.homeContainerMobile .prevArrow,
.homeContainerMobile .nextArrow {
  display: none !important;
}
.disabled {
  display: none !important;
}
.destinations {
  margin-bottom: 50px;
}
.sliderDestinations {
  position: relative;
  padding-right: 20px;
}
.sliderDestinations .backgroundOuter {
  max-width: 210px;
  height: 250px;
  overflow: hidden; 
  border-radius: 4px;
}
.homeContainerMobile .sliderDestinations .backgroundOuter {
  width: 210px;
}
.sliderDestinations .background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s ease;
}
.sliderDestinations:hover .background {
  transform: scale(1.2);
}
.homeContainerMobile .sliderDestinations:hover .background {
  transform: scale(1);
}
.sliderDestinations .country {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 0;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #FFFFFF;
}
.visaSection {
  display: inline-flex;
  justify-content: space-around;
  margin: 60px auto 0;
  padding: 30px 0 50px;
  border-top: 1px solid #D5D3E1;
  border-bottom: 1px solid #D5D3E1;
}
.homeContainerMobile .visaSection {
  padding-bottom: 40px;
}
.visaSection > div:first-child {
  flex: 1;
  margin-right: 76px;
}
.homeContainerMobile .visaSection > div:first-child {
  margin-right: 0;
}
.visaSection h1 {
  font-family: Lato;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #454452;
  margin: 0 0 55px;
  text-transform: capitalize;
}
.homeContainerMobile .visaSection h1 {
  font-size: 24px;
  margin-bottom: 30px;
}
.homeContainerMobile .visaSection {
  flex-direction: column;
  align-items: center;
}
.visaSection .container {
  display: inline-flex;
  justify-content: space-around;
}
.visaSection .container:nth-child(2) {
  margin-bottom: 28px;
}
.homeContainerMobile .visaSection .container {
  margin-bottom: 0;
}
.visaSection .container > div {
  flex: 1;
}
.homeContainerMobile .visaSection .container {
  flex-direction: column;
}
.visaBackground {
  margin-top: 30px;
  background-image: url(common/assets/image_home.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  z-index: -1;
  width: 367px;
}
.homeContainerMobile .visaBackground {
  width: 100%;
  height: 262px;
  margin-top: 0;
}
.visaSection .card {
  max-width: 320px;
}
.visaSection .card:first-child {
  margin-right: 50px;
}
.homeContainerMobile .visaSection .card {
  margin-bottom: 37px;
}
.homeContainerMobile .visaSection .card:first-child {
  margin-right: 0;
}
.visaSection .card svg {
  height: 40px;
}
.visaSection .card h5 {
  margin: 22px 0 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #454452;
}
.homeContainerMobile .visaSection .card h5 {
  margin: 10px 0;
}
.visaSection .card p { 
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #5C5B66;
  margin: 0;
}