.container {
  flex: 1;
  display: flex;
}
.orderContainer {
  position: relative;
  margin: 30px auto 50px;
  width: 100%;
  flex: 1 1;
  display: flex;
  padding: 0 !important;
}
.orderContainerMobile {
  flex-direction: column;
}
.separator {
  margin: 30px 0;
  height: 1px;
  background: #E5E4EE;
  width: 100%;
}
.titleContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 5px;
}
.orderContainerMobile .titleContainer {
  margin: 0 0 5px;
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #454452;
  margin: 0 0 24px;
}
.visaName {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #454452;
  margin: 0;
}
.visaTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
  margin: 30px 0 15px;
}
.price {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #454452;
  margin: 0;
}
.subTitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #858297;
  margin: 0 0 15px;
}
.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #858297;
}
.infoBlock {
  flex: 1 1;
  margin-right: auto;
  max-width: 525px;
}
.orderContainerMobile .infoBlock {
  margin: 0 30px;
}
.generalBlock {
  position: relative;
  margin-top: 30px;
  width: 300px;
  min-height: 350px;
  padding: 25px 30px;
  background-color: #fff;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.generalBlock .separator {
  margin: 15px 0;
}
.orderContainerMobile > div:nth-child(2) {
  margin: 30px auto 0;
}
.paymentFooter {
  margin: 26px 0 0;
}
.payment {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #B1AFBA;

  display: flex;
  justify-content: center;
  align-items: center;
}
.payment:last-child {
  margin-top: 6px;
  text-transform: initial;
}
.payment>svg {
  margin-right: 8px;
}
.payment>svg path {
  fill: #B1AFBA;
}
.orderContainerMobile .generalBlock {
  width: 300px;
  margin: 0;
}
.generalBlock .summaryItem {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
  display: flex;
}
.summaryItem:first-child {
  margin-right: 20px;
}
.additionalContainer .summaryItem:first-child {
  flex: 1;
  max-width: 140px;
  justify-content: space-between;
}
.summaryItem:first-child>div {
  height: 18px;
}
.summaryItem svg {
  margin-left: 8px;
}
.service {
  color: #858297;
  font-size: 14px;
  margin: 5px 0 0;
}
.service span {
  font-weight: 500;
}
.generalBlock .summaryTotal {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #454452;
}
.generalBlock>.summaryTotal {
  text-align: center;
  margin-top: 5px;
}
.orderTitle {
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #454452;
}
.summaryContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.orderTitle+.summaryContainer .summaryItem:first-child {
  line-height: 19px;
}
.serviceContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}
.more .serviceContainer {
  margin-bottom: 0;
}
.additionalContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 10px;
}
.steps {
  width: 100%;
  margin-bottom: 18px;
  display: inline-flex;
  justify-content: space-between;
}
.steps:last-child {
  margin-bottom: 0;
}
.selectBtn {
  height: 46px;
  width: 176px;
  margin: 25px auto 0;
  padding: 0;
    
  display: flex;
  align-items: center;
  justify-content: center;
    
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
    
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  
  transition: .3s;

  color: #ffffff;
  background-color: #31e495;
}
.selectBtn:hover {
  cursor: pointer;
  background-color: #2eda8f;
}
.selectBtn:focus {
  color: #fff;
}
.selectBtn:active {
  background-color: #35c988;
}
.link {
  display: block;
  font-weight: 500;
}
.link svg {
  margin-left: 8px;
  width: 7.59px;
  height: 7.59px;
  transform: rotate(90deg);
}
.link.isOpen svg {
  transform: rotate(-90deg);
}
.steps {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  margin-bottom: 14px;
  padding-left: 18px;
  position: relative;
}
.steps .description {
  color: #454452;
}
.steps>svg {
  position: absolute;
  top: 8px;
  left: 0;
}

.modal {
  width: 260px;
}
.modalFooter {
  font-size: 14px;
  color: #5c5b66;
  margin: 17px 0 0;
}
.modalButton {
  max-width: 136px;
  margin: 10px auto 17px;
}
.add {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 460px;
}
.add>div:first-child {
  margin-left: -13px;
}
.add h5 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #454452;
  margin: 12px 0 0;
}
.add p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #858297;
  margin: 0;
}