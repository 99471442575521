.DropdownContainer {
  width: 100%;
}
.DropdownContainer div[type="text"] > div {
  padding: 10px 0 0;
}
.DropdownContainer>div:nth-child(3)>div>div:last-child {
  border-bottom: 0 !important;
}
/*custom scroll*/
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar {
  width: 8px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-track {
  background: transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar>* {
  background:transparent;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.DropdownContainer>div:nth-child(3)::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}