.tabContainer {
  overflow: hidden;
  margin: 18px 0 30px;
  border-top: 1px solid #E5E4EE;
  border-bottom: 1px solid #E5E4EE;
}
.tabLinks {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-flex;
  justify-content: start;
  border-bottom: 1px solid #E5E4EE;
}
.tabLinks > li {
  margin-right: 20px;
}
.tabLinks > li:last-child {
  margin-right: 0;
}
.tabContent {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 16px;
}
.tabLink {
  display: inline-flex;
  padding: 11px 9px 6px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #858297;
  text-decoration: none;
}
.tabLink.active {
  font-weight: 500;
  color: #454452;
  border-bottom: 1px solid #31e495;
}
.tabLink.oneTab {
  color: #454452;
}