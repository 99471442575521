.container {
  flex: 1;
  display: flex;
  background-color: #F7F6FB;
}
.providerContainer {
  position: relative;
  margin: 30px auto 50px;
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-width: 956px !important;
}
.providerContainerMobile {
  flex: initial;
  flex-direction: column;
  margin: 30px 30px 50px;
}
.SPTheme.providerContainer a {
  color: #0459FF !important;
}
.SPTheme.providerContainer svg path {
  fill: #0459FF !important;
}
.separator {
  margin: 30px 0;
  height: 1px;
  background: #E5E4EE;
  width: 100%;
}
.infoBlock {
  flex: 1 1;

  margin-bottom: 30px;
}
.infoBlock .visaName {
  display: inline-flex;
  align-items: center;
}
.infoBlock .visaName>svg {
  margin-right: 10px;
}
.headerBlock {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.infoBlock .separator {
  margin-top: 20px;
}
.providerName {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #454452;
  margin: 0;
}
.titleContainer {
  display: inline-flex;
}
.titleContainer>svg {
  width: 115px;
  margin-right: 35px;
}
.titleContainer>div {
  flex: 1;
}
.providerContainerMobile .titleContainer {
  flex-direction: column;
  text-align: center;
}
.providerContainerMobile .titleContainer>svg {
  margin: 0 auto 24px;
}
.visaName {
  font-weight: 500;
  font-size: 22px;
  line-height: 23px;
  color: #454452;
  margin-bottom: 10px;
}
.visaDescription {
  font-weight: normal;
  white-space: pre-line;
  font-size: 14px;
  line-height: 26px;
  color: #454452;
  margin: 0;
}
.link {
  display: block;
  font-weight: 500;
  margin-top: 14px;
}
.link svg {
  margin-left: 8px;
  width: 7.59px;
  height: 7.59px;
  transform: rotate(90deg);
}
.link.isOpen svg {
  transform: rotate(-90deg);
}
.providerPhoto {
  height: 38px;
  width: 38px;
  margin-right: 11px;

  border-radius: 50%;

  display: inline-flex;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
}
.visa {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid #D5D3E1;
  border-radius: 6px;
  padding: 30px;
}
.visa:last-child {
  margin-bottom: 0;
}
.visa .visaName {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: end;
  min-width: 100px;
  text-transform: capitalize;
}
.visa .visaDescription {
  margin-right: 70px;
}
.providerContainerMobile .visa .visaDescription {
  margin-right: 0;
}
.visa .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.visa .footer .visaName {
  margin-bottom: 0;
}
.providerContainerMobile .footer {
  flex-direction: column;
}
.visa .visaName>svg {
  margin-right: 10px;
}
.visa .visaTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
  margin: 30px 0 15px;
}
.visa .selectBtn {
  width: 176px;
  height: 46px;
  padding: 0;
  margin: 0;
    
  display: flex;
  align-items: center;
  justify-content: center;
    
  font-size: 14px;
  line-height: 17px;
    
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;
  
  transition: .3s;

  color: #ffffff;
  background-color: #31e495;
}
.providerContainerMobile .visa .separator {
  margin: 30px -30px;
  width: initial;
}
.selectBtn:hover {
  cursor: pointer;
  background-color: #2eda8f;
}
.selectBtn:focus {
  color: #fff;
}
.selectBtn:active {
  background-color: #35c988;
}
.providerContainerMobile .selectBtn {
  margin: 15px auto 0;
}
.steps {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #454452;
  margin-bottom: 14px;
  padding-left: 18px;
  position: relative;
}
.steps:last-child {
  margin-bottom: 0;
}
.steps>svg {
  position: absolute;
  top: 8px;
  left: 0;
}

.modalFooter {
  font-size: 14px;
  color: #5c5b66;
  margin: 17px 0 0;
}
.modalButton {
  max-width: 136px;
  margin: 10px auto 17px;
}


.messageContainer {
  background: #fff;
  border: 1px solid #B7B3CE;
  box-shadow: 0px 9px 30px rgba(103, 105, 118, 0.04);
  border-radius: 2px;
  position: relative;
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.messageContainer .icon {
  width: 25.44px;
  height: 36.52px;
  margin: 0 14px 0 20px;
}
.messageContainer .close {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 13px;
  right: 12px;
  cursor: pointer;
}
.messageContainer p {
  margin: 16px 0 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #454452;
}
.providerContainerMobile .messageContainer p {
  max-width: 190px;
}