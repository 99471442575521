.container {
  position: relative;
  width: 33px;
  height: 22px;
}
.container:hover {
  cursor: pointer;
}
.container:hover div {
  display: block;
}
.container div {
  position: absolute;
  bottom: 30px;
  left: -75px;
  padding: 8px;
  width: 247px;
  height: 78px;
  background: #F3F2F9;
  border: 1px solid #B7B3CE;
  display: none;
}
.container span {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 21px;
  color: #5C5B66;
}