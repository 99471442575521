.container {
  flex: 1;
  display: flex;
}
.confirmContainer {
  position: relative;
  margin: 60px auto 50px;
  width: 100%;
  flex: 1 1;
  display: flex;
  padding: 0 !important;
}
.confirmContainerMobile {
  flex-direction: column;
}
.separator {
  margin: 15px 0;
  height: 1px;
  background: #E5E4EE;
  width: 100%;
}
.titleContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin: 50px 0 20px;
}
.icon {
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
}
.title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #454452;
  margin: 0 auto 16px;
  max-width: 267px;
}
.subTitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #454452;
  margin: 0 auto 30px;
  max-width: 486px;
}
.confirmContainerMobile .subTitle {
  max-width: 267px;
}
.visaName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #454452;
}
.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #858297;
  text-align: center;
}
.confirmTitle {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #454452;
}
.summaryContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.summaryContainer:last-child {
  margin-top: 10px;
}
.messenger {
  max-width: 256px;
  margin: 0 auto;
}
.generalBlock .title {
  text-align: left;
}
.generalBlock {
  margin: 0 auto 33px;
  width: 300px;
  height: fit-content;
  padding: 25px 30px;
  background-color: #fff;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
}
.generalBlock a>svg path { 
  fill: #fff;
}
.confirmContainerMobile {
  width: 300px;
}
.confirmContainerMobile .generalBlock { 
  width: 300px;
}
.generalBlock .summaryItem {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
}
.summaryItem:first-child {
  margin-right: 20px;
}
.orderTitle+.summaryContainer .summaryItem:first-child {
  line-height: 19px;
}
.service {
  color: #858297;
  font-size: 14px;
  margin: 5px 0 0;
}
.service span {
  font-weight: 500;
}
.generalBlock .summaryTotal {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #454452;
}
.summaryContainer .summaryTotal {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
}
.orderTitle {
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #454452;
}