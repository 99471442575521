.headerContainer {
  height: 70px;
  background: #272729;
  width: 100%;
}
.headerContainerMobile {
  padding: 0 15px;
}
.headerContainer.isOpen {
  position: relative;
}
.headerContainer.isOpen:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.contentContainer svg rect:nth-child(3) {
  fill: #fff;
}
.rightSide {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.isHomePage {
  background: #fff;
  border-bottom: 1px solid #E5E4EE;
  position: fixed;
  z-index: 2;
}
.isHomePage button>svg path {
  fill: #454452;
}
.isHomePage span>svg path {
  fill: #454452;
}
.isHomePage a div>svg path {
  fill: #454452;
}
.isHomePage svg rect:nth-child(3){
  fill: #1D1F52;
}
.isHomePage a,
.isHomePage .rightSide>div {
  color: #454452;
}
.isHomePage a {
  color: #454452 !important;
  font-weight: 500;
}
.isHomePage a:hover,
.isHomePage a:focus {
  color: #454452;
}