.wrapper {
  max-width: 525px;
  text-align: center;
  margin: 75px auto;
}
.wrapper svg {
  margin-bottom: 62px;
}
.wrapperMobile svg {
  margin-bottom: 32px;
}
.wrapper h1 {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  color: #454452;
  margin-bottom: 20px;
}
.wrapperMobile h1 {
  max-width: 255px;
  margin: 0 auto 22px;
  font-size: 24px;
  line-height: 32px; 
}
.wrapper>p {
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;

  color: #454452;
  margin-bottom: 40.25px;
}
.wrapperMobile>p {
  max-width: 270px;
  font-size: 20px;
  line-height: 26px; 
  margin: 0 auto 22px;
}
.wrapper .Button {
  margin: 0 auto;
  max-width: 136px;
}