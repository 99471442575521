.wrapper {
  position: relative;
  width: 100%;
  flex: 1;
}

.loader {
	height: 32px;
  width: 32px;
  display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.loader::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: #31e495;
	border-radius: 50%;
  animation: loader-4-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-4-1 {
	0%   { transform: scale(0); opacity: 0; }
	50%  { transform: scale(1); opacity: 1; }
	100% { transform: scale(0); opacity: 0; }
}
.loader span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
  animation: loader-4-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-4-2 {
	0%   { transform: rotate(0deg); }
	50%  { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}
.loader span::before,
.loader span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: #31e495;
	border-radius: 50%;
  animation: loader-4-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-4-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader span::after {
  animation: loader-4-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-4-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(16px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
