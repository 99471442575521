.registrationTooltipContainer {
  position: relative;
  display: inline;
}
.registrationTooltipContainer div {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  width: 60px;
  height: 30px;
  background: #F3F2F9;
  border: 1px solid #B7B3CE;

  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.04em;

  color: #5C5B66;
}



/*Styles for File upload*/
.fileUploadContainer form {
  display: inline-flex;
  width: 100%;
  height: 76px;
  border: 1px dashed #B7B3CE;
  margin-bottom: 10px;
}
.fileUploadContainer form:hover {
  border: 1px dashed #858297;
}
.fileUploadContainer form label div {
  position: relative;
  padding-left: 25px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  color: #0459FF;
}
.fileUploadContainer form label div:after {
  content: url(common/assets/plus.svg);
  position: absolute;
  left: 0;
}
.fileUploadContainer .label {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #858297;
  margin-bottom: 12px;
}
.fileUploadContainer form label:hover {
  cursor: pointer;
}
.tip {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #b1afba;
  letter-spacing: initial;
}

.fileUploadContainer form label {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.fileUploadContainer form label input {
  width: 0;
}
.filePreview {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  border: 1px solid #B7B3CE;
  padding: 18px 66px 18px 20px;
  margin-bottom: 10px;
}
.filePreview .name {
  max-width: 195px;
  margin-right: 30px;
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: lowercase;
  color: #454452;
}
.filePreview img {
  width: auto;
  height: 54px;
  border: 1px solid #E5E4EE;
}
.filePreview .remove {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.filePreview .remove:hover {
  cursor: pointer;
}
.fileUploadContainer .error {
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
.fileUploadContainer .submit {
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #0459FF;
}