.wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.wrapperMobile {

}
.none {
  display: none;
}
.number {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #B1AFBA;

  width: 22px;
  height: 22px;
  border: 1px solid #B1AFBA;
  border-radius: 50%;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
}
.name {
  color: #B1AFBA;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.active .number {
  border: 1px solid #31e495;
  background-color: #EAFFF6;
  color: #454452;
}
.wrapperMobile.active .number,
.wrapperMobile.active .name {
  border: none;
  background-color: transparent;
  color: #454452;
  margin-right: 2px;
  width: auto;

  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
}
.wrapperMobile.active .of {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #858297;
}
.active .name {
  color: #454452;
}
.wrapper > svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.wrapperMobile > svg {
  display: none;
}
.wrapper > svg path {
  fill: #B1AFBA;
}