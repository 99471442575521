.container {
  flex: 1;
  display: flex;
}
.providerContainer {
  position: relative;
  margin: 30px auto 50px;
  width: 100%;
  flex: 1 1;
  display: flex;
  padding: 0 !important;
}
.providerContainerMobile {
  flex-direction: column;
}
.SPTheme.providerContainer a {
  color: #0459FF !important;
}
.SPTheme.providerContainer svg path {
  fill: #0459FF !important;
}
.backgroundHeader {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  height: 290px;
  background-color: #31e495;
}
.backgroundHeader.SPTheme {
  background-color: #0459FF;
}
.backgroundHeaderMobile {
  height: 370px;
}
.infoBlock,
.listBlock,
.generalBlock {
  width: 300px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
}
.providerContainerMobile .listBlock,
.providerContainerMobile .infoBlock {
  width: 300px;
  margin-left: auto;
  margin-right: auto
}
.generalBlock {
  width: 638px;
  margin-left: 20px;
}
.providerContainerMobile .generalBlock {
  margin: 20px auto 0;
  width: 300px;
}
.providerContainerMobile .generalBlock ul {
  width: 100%;
}
.providerContainerMobile .generalBlock ul+div {
  overflow: auto;
}
.providerContainerMobile .generalBlock .tabContent {
  width: 576px;
}

.providerContainerMobile .generalBlock .tabContent2 {
  width: 380px;
}
.providerContainerMobile .generalBlock .tabContent2 .card:nth-child(2n) {
  margin-right: 0;
}

.providerContainerMobile .generalBlock .tabContent1 {
  width: auto;
}
.providerContainerMobile .generalBlock .tabContent1 .card:nth-child(n) {
  margin-right: 0;
  width: 100%;
  padding: 30px;
}

.providerContainerMobile .generalBlock ul>li {
  margin-right: auto;
}
.providerContainerMobile .generalBlock ul>li:nth-child(4) {
  margin: 0;
}
.providerPhotoContainer {
  margin: 0 auto 15px;
  width: 150px;
  height: 150px;

  border: 1px solid #a4f6d2;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.SPTheme .providerPhotoContainer {
  border: 1px solid #B3CDFF;
}
.providerPhoto {
  height: 120px;
  width: 120px;

  border-radius: 50%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
}
.providerName {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  text-transform: capitalize;
  text-align: center;
  color: #454452;
  margin-bottom: 2px;
}
.providerType {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  text-align: center;
  color: #858297;
  margin-bottom: 17px;
}
.separator {
  margin: 30px 0;
  height: 1px;
  background: #E5E4EE;
  width: 100%;
}
.residenceCountry,
.createdAt {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #454452;
}
.residenceCountry {
  margin-bottom: 15px;
}
.licensedYear {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #454452;
}
.licensedYear svg {
  margin-right: 7px;
}
.residenceCountry span,
.createdAt span {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #858297;
}
.residenceCountry span svg,
.createdAt span svg {
  margin-right: 7px;
}
.listBlock {
  margin-top: 20px;
}
.listItem {
  margin-bottom: 18px;
  height: 20px;
}
.listItem:last-child {
  margin-bottom: 0;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #454452;
  margin-bottom: 18px;
}
.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #454452;
}
.link {
  display: block;
  font-weight: 500;
}
.link svg {
  margin-left: 8px;
  width: 7.59px;
  height: 7.59px;
  transform: rotate(90deg);
}
.link.readMore svg {
  transform: rotate(-90deg);
}
.langItem {
  margin-bottom: 21px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #454452;
}
.langItem:last-child {
  margin-bottom: 0;
}
.educationItem {
  margin-bottom: 27px;
}
.educationItem div:first-child {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #454452;
  margin-bottom: 5px;
}
.educationItem div:last-child {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  
  color: #858297;
}
.educationItem:last-child {
  margin-bottom: 0;
}
.skillItem {
  margin-bottom: 18px;
  height: 20px;
  display: flex;
  align-items: center;
}
.skillItem svg {
  margin-right: 13px;
}
.skillItem:last-child {
  margin-bottom: 0;
}
.modalFooter {
  font-size: 14px;
  color: #5c5b66;
  margin: 17px 0 0;
}
.modalButton {
  max-width: 136px;
  margin: 10px auto 17px;
}


.card {
  width: 184px;
  height: 140px;

  border: 1px solid #D5D3E1;
  border-radius: 4px;
  text-decoration: none;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: none;

  padding: 18px 13px 9px 20px;
  margin: 0 12px 14px 0;
}
.card:nth-child(3n) {
  margin-right: 0;
}
.card.active {
  border: 1px solid #31E495;
  padding-top: 30px;
  justify-content: flex-start;
}
.SPTheme .card.active {
  border: 1px solid #0459FF;
}
.card .typeName {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #858297;
  margin-bottom: 3px;
}
.card .description {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 150px;
  letter-spacing: initial;
  color: #B1AFBA;
}
.card.active .typeName {
  font-weight: 500;
  color: #454452;
  margin: 8px 0 12px;
}
.card.active .description {
  font-weight: normal;
  font-size: 16px;
  color: #454452;
}
