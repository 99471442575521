.container {
  flex: 1;
  display: flex;
}
.paymentContainer {
  position: relative;
  margin: 30px auto 50px;
  width: 100%;
  flex: 1 1;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
}
.paymentContainer .container {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.paymentContainerMobile .container {
  flex-direction: column;
}
.infoBlock {
  max-width: 484px;
  flex: 1;
}
.items>svg {
  margin-left: 6px;
}
.option {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.option.selected {
  border: 1px solid #31e495;
}
.option > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option:last-child {
  margin-bottom: 0;
}
.paymentContainerMobile .option label {
  max-width: 120px;
  margin-right: 0;
}
.option .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper>* {
  max-width: 100% !important;
  min-height: 520px !important;
}
.paymentContainerMobile .wrapper>* {
  min-height: 510px !important;
}
.paymentContainerMobile .infoBlock {
  width: 300px;
  margin: 0 auto;
}
.paymentContainerMobile {
  flex-direction: column;
}
.separator {
  margin: 20px 0;
  height: 1px;
  background: #E5E4EE;
  width: 100%;
}
.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #454452;
  margin: 0 0 27px;
}
.paymentContainerMobile .title {
  margin: 0 auto 27px;
  width: 300px;
}
.generalBlock {
  width: 300px;
  padding: 25px 30px;
  background-color: #fff;
  border: 1px solid #E5E4EE;
  border-radius: 6px;
}
.more .serviceContainer {
  margin-bottom: 0;
}
.generalBlock .paypal {
  margin: 25px auto 0;
  width: 100%;
}
.loaderContainer {
  display: flex;
  margin-left: auto;
  min-width: 120px;
}
.error {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f44336;
  text-align: center;
  margin: 0 30px 10px;
}
.paymentContainerMobile .generalBlock {
  width: 300px;
  margin: 25px auto 0;
}
.generalBlock .summaryItem {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #454452;
  display: flex;
}
.summaryItem:first-child {
  margin-right: 20px;
}
.additionalContainer .summaryItem:first-child {
  flex: 1;
  max-width: 140px;
  justify-content: space-between;
}
.orderTitle+.summaryContainer .summaryItem:first-child {
  line-height: 19px;
}
.summaryItem:first-child>div {
  height: 18px;
}
.withTooltip svg {
  margin-left: 8px;
}
.service {
  color: #858297;
  font-size: 14px;
  margin: 5px 0 0;
}
.service span {
  font-weight: 500;
}
.generalBlock .summaryTotal {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #454452;
}
.summaryContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.serviceContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}
.additionalContainer {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 10px;
}
.orderTitle {
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #454452;
}
.payment {
  margin: 26px auto 0;
  width: 300px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #B1AFBA;
}
.payment a {
  font-size: 12px;
}