.header {
  height: 70px;
  border-bottom: 1px solid #E5E4EE;
}
.priceContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container {
  height: 100%;
  display: flex;
  align-items: center;
}
.breadcrambsContainer {
  margin-left: 46px;
  flex: 1;
}
.breadcrambsContainer > div {
  margin-right: 20px;
  padding-right: 25px;
}
.breadcrambsContainerMobile {
  margin-left: 0;
  text-align: right;
}
.breadcrambsContainerMobile > div {
  margin-right: 0;
  padding-right: 0;
  flex-direction: row-reverse;
}
