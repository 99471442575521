.startContainer {
  margin: 40px auto 50px;
  width: 100%;
  flex: 1;
  max-width: 958px !important;
  padding: 0 !important;
}
.startContainerMobile {
  padding: 0 30px !important;
}
.startContainer h1 {
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 43px;

  color: #454452;
}
.startContainer h1:nth-child(2) {
  margin-bottom: 38px;
}
.startContainerMobile h1:nth-child(2) {
  margin: 0 0 30px;
}
.startContainer h5 {
  margin: 0 0 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: #5C5B66;
}
.startContainer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.startContainer li {
  display: flex;
  margin-bottom: 20px;
}
.startContainer li:last-child {
  margin-bottom: 0;
}
.startContainer li svg {
  width: 25px;
}
.startContainer li span {
  flex: 1;
  margin-left: 18px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #454452;
}
.startButton {
  max-width: 239px;
  margin: 25px auto 0;
}
