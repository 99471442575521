.phoneInput {
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
  border-bottom: 1px solid #b7b3ce;
}
.phoneError .phoneInput {
  border-bottom: 1px solid #f44336;
}
.phoneInput input {
  padding: 14px 0 10px;
  color: #454452;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.04em;
  border: 0;
}
.phoneInput input::placeholder {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.select,
.select>div {
  position: initial !important;
}
.select button {
  border: none !important;
}
.select button+div {
  width: 100% !important;
  left: 0;
  top: 85px;
}
.select button+div ul {
  padding: 0;
}
.select button+div li button {
  height: 50px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.04em !important;
  color: #454452 !important;
  border-bottom: 1px solid #B7B3CE !important;
}
.select button+div li button:hover {
  background-color: #fff !important;
  color: #31E495 !important;
}
.select button+div li button[aria-selected=true] {
  background-color: #fff !important;
  color: #31E495 !important;
}
.phoneInput input:focus {
  border: 0;
}
.phoneContainer {
  position: relative;
  width: 100%;
  margin-top: 16px;
}
.phoneContainer .error {
  margin-bottom: 0;
  color: #f44336;
  position: absolute;
  font-size: 0.75rem;
  top: 50px;
  margin-top: 0 !important;
  letter-spacing: initial;
}
.phoneInput label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

/*custom scroll*/
.select button+div ul::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar {
  width: 0px;
}
.select button+div ul::-webkit-scrollbar-track {
  background: transparent;
}
.select button+div ul::-webkit-scrollbar>* {
  background:transparent;
}
.select button+div ul::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
.select button+div ul::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
.select button+div ul::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}