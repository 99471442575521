.container {
  display: inline-flex;
  align-items: center;
}
.container>span {
  margin: 0 12px 0 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #454452;
}
.container .disabled path:first-child {
  fill: #DFE7F2;
  stroke: #DFE7F2;
}
.container .disabled path:nth-child(2),
.container .disabled path:nth-child(3) {
  fill: #B4C6DC;
}